<template>
  <v-form
    v-if="$store.state.user"
    @submit.prevent="submitForm"
    autocomplete="on"
  >
    <v-card-text>
      <v-container>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              name="fname"
              v-model="form.firstname"
              :error-messages="firstnameErrors"
              :success="shouldAppendValidClass($v.form.firstname)"
              autocomplete="given-name"
              @blur="$v.form.firstname.$touch()"
            >
              <template v-slot:label>
                {{ $t("forms.user.firstName") }}
                <span class="error--text">*</span>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              name="lname"
              v-model="form.lastname"
              :error-messages="lastnameErrors"
              :success="shouldAppendValidClass($v.form.lastname)"
              autocomplete="family-name"
              @blur="$v.form.lastname.$touch()"
            >
              <template v-slot:label>
                {{ $t("forms.user.lastName") }}
                <span class="error--text">*</span>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="12">
            <v-text-field
              :label="$t('forms.user.company')"
              name="company"
              v-model="form.company"
              :error-messages="companyErrors"
              autocomplete="organization"
              @blur="$v.form.company.$touch()"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12">
            <v-text-field
              name="address"
              v-model="form.street"
              :error-messages="streetErrors"
              :success="shouldAppendValidClass($v.form.street)"
              autocomplete="street-address"
              @blur="$v.form.street.$touch()"
            >
              <template v-slot:label>
                {{ $t("forms.user.street") }}
                <span
                  v-if="form.createTimingPointAccount || hasTimingPointAccount"
                  class="error--text"
                  >*</span
                >
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="4">
            <v-text-field
              :label="$t('forms.user.postalCode')"
              name="postal"
              v-model="form.postalCode"
              :error-messages="postalCodeErrors"
              autocomplete="postal-code"
              @blur="$v.form.postalCode.$touch()"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="8">
            <v-text-field
              name="city"
              v-model="form.city"
              :error-messages="cityErrors"
              :success="shouldAppendValidClass($v.form.city)"
              autocomplete="address-level2"
              @blur="$v.form.city.$touch()"
            >
              <template v-slot:label>
                {{ $t("forms.user.city") }}
                <span
                  v-if="form.createTimingPointAccount || hasTimingPointAccount"
                  class="error--text"
                  >*</span
                >
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="12">
            <v-autocomplete
              name="country"
              v-model="form.country"
              :error-messages="countryErrors"
              :success="shouldAppendValidClass($v.form.country)"
              :items="getItemsByLang"
              hide-no-data
              auto-select-first
              autocomplete="new-password"
              @blur="$v.form.country.$touch()"
            >
              <template v-slot:label>
                {{ $t("forms.user.country") }}
                <span
                  v-if="form.createTimingPointAccount || hasTimingPointAccount"
                  class="error--text"
                  >*</span
                >
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="12" sm="12">
            <v-text-field
              :label="$t('forms.user.phoneNumber')"
              name="phone"
              v-model="form.phoneNumber"
              :error-messages="phoneNumberErrors"
              autocomplete="tel"
              @blur="$v.form.phoneNumber.$touch()"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12">
            <v-text-field
              :label="$t('forms.user.cellPhoneNumber')"
              name="mobile"
              v-model="form.cellPhoneNumber"
              :error-messages="cellPhoneNumberErrors"
              autocomplete="tel"
              @blur="$v.form.cellPhoneNumber.$touch()"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12">
            <v-text-field
              name="email"
              type="email"
              v-model="form.email"
              :error-messages="emailErrors"
              :success="shouldAppendValidClass($v.form.email)"
              autocomplete="email"
              @blur="$v.form.email.$touch()"
            >
              <template v-slot:label>
                {{ $t("forms.general.email.label") }}
                <span class="error--text">*</span>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="12">
            <v-text-field
              :label="$t('forms.user.website')"
              placeholder="https://example.com"
              name="website"
              v-model="form.website"
              :error-messages="websiteErrors"
              autocomplete="url"
              @blur="$v.form.website.$touch() && checkUrl"
            ></v-text-field>
          </v-col>

          <v-col v-if="passwordRequired" cols="12" sm="12">
            <v-text-field
              name="password"
              type="password"
              v-model="form.password"
              :error-messages="passwordErrors"
              :success="shouldAppendValidClass($v.form.password)"
              autocomplete="new-password"
              @blur="$v.form.password.$touch()"
            >
              <template v-slot:label>
                {{ $t("forms.general.password.label") }}
                <span class="error--text">*</span>
              </template>
            </v-text-field>
          </v-col>

          <v-col v-if="passwordRequired" cols="12" sm="12">
            <v-text-field
              name="passwordConfirmation"
              type="password"
              v-model="form.passwordConfirmation"
              :error-messages="passwordConfirmationErrors"
              :success="shouldAppendValidClass($v.form.passwordConfirmation)"
              @blur="$v.form.passwordConfirmation.$touch()"
            >
              <template v-slot:label>
                {{ $t("forms.general.passwordConfirmation.label") }}
                <span class="error--text">*</span>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="12">
            <v-checkbox
              :label="
                hasTimingPointAccount
                  ? $t('forms.signUp.hasTimingPointAccount')
                  : $t('forms.signUp.createTimingPointAccount')
              "
              name="createTimingPointAccount"
              v-model="form.createTimingPointAccount"
              :hint="$t('forms.signUp.timingPointAccountInfo')"
              :persistent-hint="!hasTimingPointAccount"
              :disabled="hasTimingPointAccount"
            ></v-checkbox>
          </v-col>

          <v-col v-if="showConsent" cols="12" sm="12">
            <v-checkbox
              v-model="form.consent"
              :label="$t('forms.signUp.consent')"
              :error-messages="consentErrors"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-alert v-if="error" class="mt-6" dense outlined type="error">
          {{ error }}
        </v-alert>
        <v-alert v-else-if="success" class="mt-6" dense outlined type="success">
          {{ success }}
        </v-alert>
      </v-container>
      <slot>
        <v-card-actions v-if="showButton">
          <v-spacer></v-spacer>
          <v-btn type="submit" color="success" :loading="isLoading">
            {{ submitLabel }}
          </v-btn>
        </v-card-actions>
      </slot>
    </v-card-text>
  </v-form>
</template>

<script>
/* eslint-disable */

import Url from "url-parse";
import { mapGetters } from "vuex";
import {
  required,
  requiredIf,
  url,
  email,
  sameAs,
  minLength,
  maxLength
} from "vuelidate/lib/validators";

export default {
  name: "FormUser",
  props: {
    user: {
      type: Object,
      default: null
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: null
    },
    success: {
      type: String,
      default: null
    },
    showButton: {
      type: Boolean,
      default: true
    },
    showConsent: {
      type: Boolean,
      default: false
    },
    passwordRequired: {
      type: Boolean,
      default: false
    },
    submitText: {
      type: String,
      default: "forms.signUp.submit"
    }
  },
  data() {
    return {
      form: {
        firstname: this.user ? this.user.firstname : null,
        lastname: this.user ? this.user.lastname : null,
        company: this.user ? this.user.company : null,
        street: this.user ? this.user.address.street : null,
        postalCode: this.user ? this.user.address.postalCode : null,
        city: this.user ? this.user.address.city : null,
        country: this.user ? this.user.address.country : null,
        phoneNumber: this.user ? this.user.phoneNumber : null,
        cellPhoneNumber: this.user ? this.user.cellPhoneNumber : null,
        email: this.user ? this.user.email : null,
        website: this.user ? this.user.website : null,
        password: null,
        passwordConfirmation: null,
        createTimingPointAccount: false,
        consent: false
      }
    };
  },
  validations: {
    form: {
      firstname: {
        required,
        maxLength: maxLength(60)
      },
      lastname: {
        required,
        maxLength: maxLength(60)
      },
      company: {
        maxLength: maxLength(120)
      },
      street: {
        required: requiredIf(function() {
          return this.form.createTimingPointAccount || this.hasTimingPointAccount;
        }),
        maxLength: maxLength(120)
      },
      postalCode: {
        maxLength: maxLength(10)
      },
      city: {
        required: requiredIf(function() {
          return this.form.createTimingPointAccount || this.hasTimingPointAccount;
        }),
        maxLength: maxLength(60)
      },
      country: {
        required: requiredIf(function() {
          return this.form.createTimingPointAccount || this.hasTimingPointAccount;
        })
      },
      phoneNumber: {
        maxLength: maxLength(60)
      },
      cellPhoneNumber: {
        maxLength: maxLength(60)
      },
      email: {
        required,
        email,
        maxLength: maxLength(120)
      },
      website: {
        url,
        maxLength: maxLength(120)
      },
      password: {
        required: requiredIf(function() {
          return this.passwordRequired;
        }),
        minLength: minLength(6)
      },
      passwordConfirmation: {
        required: requiredIf(function() {
          return this.passwordRequired;
        }),
        sameAsPassword: sameAs("password")
      },
      consent: {
        required: function(value) {
          return this.showConsent ? value : true;
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      getItemsByLang: "nations/getItemsByLang",
      hasTimingPointsAccount: "user/hasTimingPointsAccount"
    }),
    firstnameErrors() {
      const errors = [];
      if (!this.$v.form.firstname.$dirty) return errors;
      !this.$v.form.firstname.required && errors.push(this.$i18n.t("forms.user.errors.firstName.required"));
      !this.$v.form.firstname.maxLength && errors.push(this.$i18n.t("forms.user.errors.firstName.maxLength", { length: 60 }));
      return errors;
    },
    lastnameErrors() {
      const errors = [];
      if (!this.$v.form.lastname.$dirty) return errors;
      !this.$v.form.lastname.required && errors.push(this.$i18n.t("forms.user.errors.lastName.required"));
      !this.$v.form.lastname.maxLength && errors.push(this.$i18n.t("forms.user.errors.lastName.maxLength", { length: 60 }));
      return errors;
    },
    companyErrors() {
      const errors = [];
      if (!this.$v.form.company.$dirty) return errors;
      !this.$v.form.company.maxLength && errors.push(this.$i18n.t("forms.user.errors.company.maxLength", { length: 120 }));
      return errors;
    },
    streetErrors() {
      const errors = [];
      if (!this.$v.form.street.$dirty) return errors;
      !this.$v.form.street.required && errors.push(this.$i18n.t("forms.user.errors.street.required"));
      !this.$v.form.street.maxLength && errors.push(this.$i18n.t("forms.user.errors.street.maxLength", { length: 120 }));
      return errors;
    },
    postalCodeErrors() {
      const errors = [];
      if (!this.$v.form.postalCode.$dirty) return errors;
      !this.$v.form.postalCode.maxLength && errors.push(this.$i18n.t("forms.user.errors.postalCode.maxLength", { length: 10 }));
      return errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.form.city.$dirty) return errors;
      !this.$v.form.city.required && errors.push(this.$i18n.t("forms.user.errors.city.required"));
      !this.$v.form.city.maxLength && errors.push(this.$i18n.t("forms.user.errors.city.maxLength", { length: 60 }));
      return errors;
    },
    countryErrors() {
      const errors = [];
      if (!this.$v.form.country.$dirty) return errors;
      !this.$v.form.country.required && errors.push(this.$i18n.t("forms.user.errors.country.required"));
      return errors;
    },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.form.country.$dirty) return errors;
      !this.$v.form.phoneNumber.maxLength && errors.push(this.$i18n.t("forms.user.errors.phoneNumber.maxLength", { length: 60 }));
      return errors;
    },
    cellPhoneNumberErrors() {
      const errors = [];
      if (!this.$v.form.cellPhoneNumber.$dirty) return errors;
      !this.$v.form.cellPhoneNumber.maxLength && errors.push(this.$i18n.t("forms.user.errors.cellPhoneNumber.maxLength", { length: 60 }));
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      !this.$v.form.email.required && errors.push(this.$i18n.t("forms.general.email.errors.required"));
      !this.$v.form.email.email && errors.push(this.$i18n.t("forms.general.email.errors.format"));
      !this.$v.form.email.maxLength && errors.push(this.$i18n.t("forms.general.email.errors.maxLength", { length: 120 }));
      return errors;
    },
    websiteErrors() {
      const errors = [];
      if (!this.$v.form.website.$dirty) return errors;
      !this.$v.form.website.url && errors.push(this.$i18n.t("forms.user.errors.website.format"));
      !this.$v.form.website.maxLength && errors.push(this.$i18n.t("forms.user.errors.website.maxLength", { length: 120 }));
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.required && this.passwordRequired && errors.push(this.$i18n.t("forms.general.password.errors.required"));
      !this.$v.form.password.minLength && errors.push(this.$i18n.t("forms.general.password.errors.minLength", { length: 6 }));
      return errors;
    },
    passwordConfirmationErrors() {
      const errors = [];
      if (!this.$v.form.passwordConfirmation.$dirty) return errors;
      !this.$v.form.passwordConfirmation.required && this.passwordRequired && errors.push(this.$i18n.t("forms.general.passwordConfirmation.errors.required"));
      !this.$v.form.passwordConfirmation.sameAsPassword && errors.push(this.$i18n.t("forms.general.passwordConfirmation.errors.match"));
      return errors;
    },
    consentErrors() {
      const errors = [];
      if (!this.$v.form.consent.$dirty) return errors;
      !this.$v.form.consent.required && errors.push(this.$i18n.t("forms.signUp.errors.consent.required"));
      return errors;
    },
    submitLabel() {
      return this.$i18n.t(this.submitText);
    },
    hasTimingPointAccount() {
      if (this.user && this.user.roles) {
        return this.user.roles.includes("TIMING_POINT_ACCOUNT");
      }

      return false;
    }
  },
  methods: {
    getCountryItem(item) {
      return this.$i18n.t(`countries.${item.id}`);
    },
    shouldAppendValidClass(field) {
      return !field.$invalid && field.$model && field.$dirty;
    },
    checkUrl(event) {
      this.$v.form.website.$touch();
      const value = event.target.value;

      if (value !== "") {
        const parsed = Url(value, {});
        const prefix = "http://";

        if (parsed.protocol === "") {
          this.form.website = `${prefix}${this.form.website}`;
        }
      }
    },
    submitForm() {
      this.$v.form.$touch();

      const form = {
        address: {
          city: this.form.city,
          country: this.form.country,
          postalCode: this.form.postalCode,
          street: this.form.street
        },
        cellPhoneNumber: this.form.cellPhoneNumber,
        company: this.form.company,
        createTimingPointAccount: this.form.createTimingPointAccount,
        email: this.form.email,
        firstname: this.form.firstname,
        lastname: this.form.lastname,
        phoneNumber: this.form.phoneNumber,
        website: this.form.website
      };

      if (this.passwordRequired) {
        form.password = this.form.password;
      }

      if (!this.$v.form.$invalid) {
        this.$emit("formSubmitted", form);
      }
    }
  },
  mounted() {
    this.form.createTimingPointAccount = this.user && this.hasTimingPointAccount;
  }
};
</script>
